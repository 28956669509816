.aboutpage{
    background:url(../../images/BG\ 1.png);
}
.aboutpagec{
    display: flex;
    justify-content:center;

}
.aboutpage-img img{
    object-fit:cover;
    width: 100%;
    height: 250px;
}
.aboutpage-img{
    filter: brightness(50%); 
}
.aboutpage-content{
    margin-top: 3%;
}
.aboutpage-content h1{
    width: 700px;
    font-weight: 700;
    font-size: 40px;

}
.aboutpage-content p{
    font-weight:500;
    font-size: 20px;
}
.aboutpage-slideshow img{
    border-radius: 20px;
    object-fit: cover;
    width: 350px;
    height: 200px;
}
.aboutpage-para{
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    align-items: center;
    margin-top: 50px;
    width: 100%;
}
.aboutpage-para p{
    width: 80%;
    font-size: 16px;
    text-align: start;
}

.aboutpage-para  h1 {
    width:80%;
}
.showcase{
    margin-left: 15%;

}
.showcase p{
    font-size: 30px;
    font-weight: 500;
}
.showcase h1,hr{
    font-weight: 700;
    font-size: 40px;
   

}
.collab{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}
.collab img{
    object-fit: cover;
}
.collab img:hover{
    transform: scale(1.2);
    transition: transform 0.3s;
}
.box{
    margin-left:50px ;
    margin-top: 20px;
}
.first-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width:991px) {
    .aboutpage-content h1{
        width: 400px;
        font-size: 30px;    
    }
    .aboutpage-slideshow img{
        width:300px;
        height:300px;
    }  
    .showcase h1,hr{
        font-weight: 700;
        font-size: 30px;
       
    
    }  
}

@media screen and (max-width:768px) {


    .aboutpagec{
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
    
    }
    .aboutpage-slideshow img{
        object-fit: cover;
        border-radius: 20px;
        width: 300px;
        height: 300px;
    }
    .aboutpage-content h1{
        width: 100%;
        font-weight: 700;
        font-size: 30px;
    
    }
    .aboutpage-content p{
        font-weight:500;
        font-size: 20px;
    }
    .aboutpage-para p {
        width: 90%;
        font-size: 15px;
    }
    .showcase{
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        text-align: center;
        margin-left: 0%;
    
    }
    .first-row{
        display: flex;
        flex-direction: column;
    }
    .second-row{
        display: flex;
        flex-direction: column;
    }
    .showcase h1{
        width: 100%;
        font-weight: 700;
        font-size: 30px;
    
    }
    .showcase p{
        font-weight:500;
        font-size: 20px;
    }
    .box{
        margin-left:0px ;
        margin-top: 50px;
    }

    
}