.category{
    position: relative;
}
.category-bgimg{
    filter: brightness(80%);
    background-position: center;
    transition: transform 0.9s ease-in-out;
}
.category-bgimg.click{
    transform: scale(1.1);
}

.category-bgimg img{
    object-fit: cover;
    width: 100%;
    height: 730px;

}
.category-heading{
    color: white;
    position: absolute;
    top:5%;
    left:5%;
}
.category-heading h2{
    font-weight: 800;
}
.categories{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    gap: 10px;
    position: absolute;
    top: 17%;
    left: 5%;
    width: 90%;

}
.category-box{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight:700 ;
    width: 150px;
    height: 80px;
    border-radius: 10px;
    background-color: rgba(0, 94, 235, 0.715);
    transition: background-color 0.5s ease;
    
}
.category-box:hover{
   background-color: rgba(131, 131, 131, 0.358);
}
.category-content{
    width: 70%;
    color: white;
    position: absolute;
    top:60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    left: 5%;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}
.category-content.click{
    opacity: 0.1;
}
.category-content p{
    width: 75%;
    font-size: 20px;
}
.certified{
    position: absolute;
    top: 80%;
    right: 10%;
    display: flex
}
.certified .iso{
    width: 95px;
    height: 95px;
    border-radius: 50%;
}
.certified .icv{
    width: 95px;
    height: 95px;
    border-radius: 50%;
}

@media screen and (max-width:820px) {
    .categories{
          display: flex;
    justify-content: center;
    align-items: center;
        top: 15%;  
         
    }
    .category-content{
        background-color:rgb(2,62,128);
        top:60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: 0%;
        width: 100%;
        height: 400px;
       
    }
    .category-bgimg img{
        height: 880px;
    }
    .category-content p{
        font-size: 12px;
        width: 90%;
    }

    .certified{
        position: absolute;
        top: 94%;
        left: 40%;
        display: flex
    }
    .certified .iso{
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    .certified .icv{
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    @media screen and (max-width:500px){
        .category-content{
            background-color:rgb(2,62,128);
            top:65%;
            width: 100%;
            height: 400px;
           
        }
        .category-bgimg img{
            height: 1150px;
        }
        .certified{
            position: absolute;
            top: 92%;
            left: 30%;
            display: flex
        }

    }
 

    
}