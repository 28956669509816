.products-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 23%;
    display: none;
    background-color: rgba(68, 68, 68, 0.74);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-inline-end: 1.5rem;
    z-index: 0;
    height: 625px;
    width: 250px;
    overflow-y: auto;
  }
  
  .products-dropdown-menu li {
    list-style-type: none;
    padding-block: 1rem;
  }
  .products-dropdown-menu a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  
  .products-dropdown-menu ul li {
    text-decoration: none;
    transform: scale(1);
    transition: transform 0.2s linear;
    line-height: 6px;
    font-size: 15px;
  }
  
  .products-dropdown-menu.visible {
    display: block;
  }
  
  .products-dropdown-menu ul li:hover {
    transform: scale(1.1);
  }