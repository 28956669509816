@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100;200;300;400;500&family=Poppins:wght@300;400;500;600;700;800&display=swap");

.servicepage{
  background:url(../../images/BG\ 1.png);
}
.servicepage-img{
    filter: brightness(50%); 
}
.servicepage-img img{
    object-fit:cover;
    width: 100%;
    height: 250px;
}
.servicepage-content{
    margin-left: 15%;
}
.servicepage-content h1{
    width: 650px;
    font-weight: 700;
    font-size: 40px;

}
.servicepage-content p{
    font-weight:500;
    font-size: 20px;
}



:root {
  font-size: 16px;
  font-family: "Poppins";
  --color-1: hsl(0, 0%, 5%);
  --color-2: hsla(0, 0%, 95%, 0.9);
  --color-3: hsl(0, 0%, 30%);
  --color-4: hsla(0, 0%, 55%, 0.5);
  --color-5: hsl(35, 81%, 56%);
  --bottom-margin: 1rem;
}


.card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-block: 3rem;
}

.cards {
  background-color: var(--color-2);
  max-width: 25rem;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  text-align: center;
  align-items: center;
  border-radius: 0.5em;
  box-shadow: 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.34);
}

.model-picture {
  width: 100%;
  border-radius: 0.25em;
  height: 20rem;
  object-fit: cover;
  transition: transform 0.25s ease, box-shadow 0.25s ease;
}

.cards:hover > a > .model-picture {
  transform: translateY(-10%);
  box-shadow: 0rem 1.2rem 1rem 0rem rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 0rem 1.2rem 1rem 0rem rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0rem 1.2rem 1rem 0rem rgba(0, 0, 0, 0.34);
}

.type {
  background-color: var(--color-1);
  font-size: 1rem;
  padding: 1em 2em;
  border-radius: 2em;
  transform: translateY(-50%);
  color: var(--color-2);
  box-shadow: 0rem 0.6rem 0.5rem 0rem rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 0rem 0.6rem 0.5rem 0rem rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0rem 0.6rem 0.5rem 0rem rgba(0, 0, 0, 0.34);
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  width: 75%;
}

.cards:hover > a > .type {
  box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.34);
  transform: translateY(-40%);
}

.card-title {
  font-size: 1.5rem;
  font-weight: 500;
  /* background-color: green; */
  margin-bottom: var(--bottom-margin);
}

.card-description {
  padding-bottom: 1rem;
  margin-bottom: calc(var(--bottom-margin) * 2);
  border: solid;
  border-width: 0 0 0.15rem 0;
  border-color: var(--color-4);
  color: var(--color-3);
  font-weight: 500;
  font-size: 0.9rem;
  text-align: start;
}

.shopping-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.cards a{
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
}



@media screen and (max-width:768px){
    .servicepage-content{
        margin-top: 10%;
        margin-left: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .servicepage-content h1 {
        width: 294px;
        font-size: 30px;
    }
    .servicepage-content p {
        font-size: 20px;
    }
    .cards{
      max-width:20rem
    }
    
}
